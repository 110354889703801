import React, { useState, useEffect } from 'react'
import { PageTemplate } from '../templates/page'
import { Link } from 'gatsby'
import { get, list, quantity, total } from 'cart-localstorage'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { roundAndFix } from '../scripts/helpers'
import { loadStripe } from '@stripe/stripe-js'
import SiteMetadata from '../components/SiteMetadata'
import ProductMetadata from '../components/ProductMetadata'
import Button from '../components/shortcodes/Button'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: 'My Order',
  pageIdentifier: "checkout",
  pageClass: "hide-cart footer-dimmed",
  showCta: false,
  showContactForm: false,
}

const PaymentPage = () => {
  const { maxProductQuantity, nationwideDeliveryCharge } = SiteMetadata()

  // Define states
  const [cart, setCart] = useState(typeof window !== 'undefined' ? list() : null)
  const [isCheckoutStep, setIsCheckoutStep] = useState(false)
  const [hasFreeDelivery, setHasFreeDelivery] = useState(false)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [agreedTerms, setAgreedTerms] = useState(false)

  // Prevent Gatsby from prerendering this page
  if (typeof window == 'undefined') {
    return false
  }

  const productMeta = ProductMetadata()

  // Show cart in console (development only)
  // console.log(cart)

  // Show product metadata (development only)
  // console.log(productMeta)

  // Loop through cart and add metadata
  const cartWithData = []
  
  cart.forEach(item => {
    const itemData = { ...item }

    itemData.productID = item.id.toString().includes('_') ? Number(item.id.toString().split('_')[0]) : Number(item.id)

    itemData.rentalMonths = item.id.toString().includes('_') ? Number(item.id.toString().split('_')[1]) : null

    itemData.total = item.id.toString().includes('_') ? Number(item.id.toString().split('_')[2]) : item.price

    productMeta.forEach(metaItem => {
      if (Number(metaItem.node.frontmatter.productID) === Number(itemData.productID)) {
        itemData.fullTitle = metaItem.node.frontmatter.fullTitle
        itemData.price = metaItem.node.frontmatter.price
        itemData.title = metaItem.node.frontmatter.title
        itemData.slug = metaItem.node.frontmatter.slug
        itemData.purchaseType = metaItem.node.frontmatter.purchaseType
        itemData.kitPrice = metaItem.node.frontmatter.kitPrice

        if (hasFreeDelivery === false && metaItem.node.frontmatter.purchaseType === 'rent') {
          setHasFreeDelivery(true)
        }
      }
    })

    cartWithData.push(itemData)
  })

  // Increase/Decreate quantity
  function changeQty(operation, id, productType) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= maxProductQuantity) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())

    // Reload the page to update all the prices with minimal effort
    // TODO: Research using Redux for this purpose in the future
    if (productType === 'rent') {
      window.location.href = '/checkout'
    }
  }

  // Agree/Disagree to Terms
  function toggleTerms(option) {
    setAgreedTerms(option)
  }

  // Change rental months
  function changeDuration(id, slug) {
    quantity(id, -1)

    setCart(list())

    // Redirect to product slug
    // TODO: Research using Redux for this purpose in the future
    window.location.href = '/' + slug
  }

  /**
   * Helper function to throw error via Toastify
   * 
   * @param {string}  message
   * @param {string}  [element] // Scroll to this element
   */
  function throwPopupError(message, element) {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })

    if (element) {
      element.scrollIntoView()
      element.focus()
    }
  }

  /**
   * Helper function to show a success message via Toastify
   * 
   * @param {string}  message
   * @param {string}  [element] // Scroll to this element
   */
  function showSuccessMessage(message, element) {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })

    if (element) {
      element.scrollIntoView()
      element.focus()
    }
  }

  /**
   * Helper function to validate an input
   * 
   * @param {string}  input
   * @param {string}  type: text, number, phone, email
   * @param {number}  [minLength=1]
   */
  function isInputValid(input, type, minLength = 1) {
    if (
      input &&
      input.value &&
      input.value.trim().length >= minLength) {
      if (type === 'text') {
        // It should be valid if minLength is satisfied
        return true
      }
      else if (type === 'number') {
        // It should be valid if minLength is satisfied
        return true
      }
      else if (type === 'phone') {
        // It should be valid if minLength is satisfied
        return true
      }
      else if (type === 'email') {
        let regex = /\S+@\S+\.\S+/

        return regex.test(input.value)
      }
    }

    return false
  }

  // Process Checkout Form
  async function processForm(event) {
    event.preventDefault()

    // Temporarily disable form submit button
    setSubmitButtonDisabled(true)
    setTimeout(() => setSubmitButtonDisabled(false), 4000)

    const partialErrorMessage = 'This field is empty or not valid: '
    const genericErrorMessage = 'Something went wrong, please refresh the page and try again or send us an enquiry by email.'

    let data = {}

    // Order metadata - Stringify all
    let metadata = {}

    // Full name
    const fullNameField = document.querySelector('input[name="name"]')

    if (isInputValid(fullNameField, 'text', 3)) {
      metadata.billing_name = fullNameField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Full Name', fullNameField)
      return
    }

    // Phone
    const phoneField = document.querySelector('input[name="phone"]')

    if (isInputValid(phoneField, 'phone', 6)) {
      metadata.billing_phone = phoneField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Phone Number', phoneField)
      return
    }

    // Email
    const emailField = document.querySelector('input[name="email"]')

    if (isInputValid(emailField, 'email', 5)) {
      metadata.billing_email = emailField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Email', emailField)
      return
    }

    // County
    const countyField = document.querySelector('select[name="county"]')

    if (isInputValid(countyField, 'text')) {
      metadata.billing_county = countyField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'County', countyField)
      return
    }

    // Full address
    const fullAddressField = document.querySelector('input[name="address"]')

    if (isInputValid(fullAddressField, 'text', 3)) {
      metadata.billing_address = fullAddressField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Address', fullAddressField)
      return
    }

    // Town/City
    const townCityField = document.querySelector('input[name="town_city"]')

    if (isInputValid(townCityField, 'text')) {
      metadata.billing_town_city = townCityField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Town/City', townCityField)
      return
    }

    // Eircode
    const eircodeField = document.querySelector('input[name="eircode"]')

    if (isInputValid(eircodeField, 'text', 7)) {
      metadata.billing_eircode = eircodeField.value
    }
    else {
      throwPopupError(partialErrorMessage + 'Eircode', eircodeField)
      return
    }

    // Line Items array
    let lineItems = []

    // Products
    const productBoxes = document.querySelectorAll('.single-product-line')

    let qualifiesForFreeDelivery = false

    if (productBoxes) {
      productBoxes.forEach(product => {
        // line item
        let lineItem = {
          quantity: Number(product.dataset.quantity),
        }

        // price_data
        let priceData = {
          // static data
          currency: 'eur',
        }

        // unit_amount * 100
        priceData.unit_amount = product.dataset.unitPrice || 0

        // product_data object
        let productData = {}

        // Product full name
        productData.name = product.dataset.productName

        // Product metadata object
        let productMetadata = { type: 'product' }

        // product id
        const productId = product.dataset.id

        if (productId) {
          productMetadata.product_id = productId
        }
        else {
          throwPopupError(genericErrorMessage + '1')
          return
        }

        // Product description
        let productDescription = ''

        // Rental products
        if (product.dataset.purchaseType === 'rent') {
          // Rental duration: X months
          productDescription += 'Rental duration: ' + String(product.dataset.rentalDuration) + ' ' + (Number(product.dataset.rentalDuration) > 1 ? 'months' : 'month') + ' | '

          // Total inclusive of X charge for starter kit
          productDescription += 'Total inclusive of €' + String(product.dataset.starterKitPrice) + ' charge for starter kit'

          qualifiesForFreeDelivery = true
        }
        else if (product.dataset.purchaseType === 'sell') {
          // Do nothing
        }
        else {
          throwPopupError(genericErrorMessage + '2')
          return
        }

        // Add nested sub-arrays/objects
        productData.metadata = productMetadata
        priceData.product_data = productData
        lineItem.price_data = priceData

        if (productDescription && productDescription.length && productDescription.length > 1) {
          productData.description = productDescription
        }

        // Add line item to the main array
        lineItems.push(lineItem)
      })
    } else {
      // There are no product boxes on the page
      throwPopupError(genericErrorMessage + '3')

      return
    }

    // Delivery as single product
    let deliveryLineItem = {
      // static data; qty is always 1 for hampers/boxes
      quantity: 1,
    }

    // Delivery price_data
    let deliveryPriceData = {
      // static data
      currency: 'eur',
    }

    // unit_amount * 100
    if (qualifiesForFreeDelivery) {
      // Push to priceData
      deliveryPriceData.unit_amount = 0
    }
    else if (nationwideDeliveryCharge) {
      let _amountDelivery = Number(nationwideDeliveryCharge) * 100

      // Push to priceData
      deliveryPriceData.unit_amount = parseInt(_amountDelivery)
    }
    else {
      throwPopupError(genericErrorMessage)
      return
    }

    // Delivery product_data object
    let deliveryProductData = {}

    // Delivery item name
    if (qualifiesForFreeDelivery) {
      deliveryProductData.name = 'Free Delivery'
    }
    else {
      deliveryProductData.name = 'Delivery'
    }

    // Delivery metadata object
    let deliveryMetadata = { type: 'delivery' }

    // Add nested sub-arrays/objects
    deliveryProductData.metadata = deliveryMetadata
    deliveryPriceData.product_data = deliveryProductData
    deliveryLineItem.price_data = deliveryPriceData

    lineItems.push(deliveryLineItem)

    // Add arrays and objects to the data object
    data.metadata = metadata

    data.line_items = lineItems

    console.log(data)

    // Send request and await sessionId
    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  // Build page if possible
  if (cart && cart.length) {
    return (
      <PageTemplate 
        title={isCheckoutStep ? 'Checkout' : pageProps.title}
        subtitle={isCheckoutStep ? 'Pay by card simply and securely' : null}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass + ' ' + (isCheckoutStep ? (CheckoutPageStyles.checkoutStep || '') : (CheckoutPageStyles.cartStep || ''))}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
        notNarrow={true}
      >
        {/* Cart Step */}
        <div className={`container is-narrow ${CheckoutPageStyles.cartStepHolder || ''}`} style={{display: (isCheckoutStep ? 'none' : 'block')}}>
          <table className={`table ${CheckoutPageStyles.table || ''}`}>
            <thead>
              <tr>
                <td>Product</td>
                <td>Price</td>
                <td>Qty</td>
                <td className="is-hidden-mobile"></td>
              </tr>
            </thead>
            <tbody>
              {cartWithData.map(item => {
                return (
                  <tr 
                    className='single-product-line'
                    key={item.id} 
                    data-id={item.id} 
                    data-quantity={item.quantity}
                    data-unit-price={(
                      item.purchaseType === 'rent'
                      ? Number(item.total)
                      : Number(item.price)
                    ) * 100}
                    data-product-name={item.name}
                    data-purchase-type={item.purchaseType}
                    data-rental-duration={item.rentalMonths || 0}
                    data-starter-kit-price={item.kitPrice || 0}
                  >
                    <td>
                      <Link to={`/${item.slug}`}>{item.name}</Link>

                      {/* Rental Options */
                        item.purchaseType === 'rent' && (
                          <>
                            <p>
                              Rental duration: {item.rentalMonths} {item.rentalMonths > 1 ? 'months' : 'month'}
                              <span className={`tag ${CheckoutPageStyles.changeDuration || ''}`}
                                onClick={() => {
                                  changeDuration(item.id, item.slug)
                                }}
                                onKeyDown={() => {
                                  changeDuration(item.id, item.slug)
                                }}
                                role="button"
                                tabIndex="0"
                              >
                                Change
                              </span>
                            </p>
                            <p>Total inclusive of &euro;{item.kitPrice} charge for starter kit</p>
                          </>
                        )
                      }
                    </td>
                    <td>&euro;{roundAndFix(item.total)}</td>
                    <td>
                      {item.quantity}
                      <div className="is-hidden-tablet">
                        {/* Show only for sellable products */
                          item.purchaseType === 'sell' && (
                          <span
                            className={`pill ${CheckoutPageStyles.increase || ''}`}
                            onClick={() => {
                              changeQty('increase', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                            }}
                            onKeyDown={() => {
                              changeQty('increase', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            +
                          </span>)
                        }
                        <span
                          className={`pill ${CheckoutPageStyles.decrease || ''} ${item.purchaseType === 'rent' ? CheckoutPageStyles.remove : ''}`}
                          onClick={() => {
                            changeQty('decrease', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                          }}
                          onKeyDown={() => {
                            changeQty('decrease', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          {item.purchaseType === 'rent' ? '+' : '--'}
                        </span>
                      </div>
                    </td>
                    <td className="is-hidden-mobile">
                      {/* Show only for sellable products */
                        item.purchaseType === 'sell' && (
                        <span
                            className={`pill ${CheckoutPageStyles.increase || ''}`}
                          onClick={() => {
                            changeQty('increase', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                          }}
                          onKeyDown={() => {
                            changeQty('increase', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          +
                        </span>)
                      }
                      <span
                        className={`pill ${CheckoutPageStyles.decrease || ''} ${item.purchaseType === 'rent' ? CheckoutPageStyles.remove : ''}`}
                        onClick={() => {
                          changeQty('decrease', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                        }}
                        onKeyDown={() => {
                          changeQty('decrease', item.id, (item.purchaseType === 'rent' ? 'rent' : 'sell'))
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        {item.purchaseType === 'rent' ? '+' : '--'}
                      </span>
                    </td>
                  </tr>
                )
              })}

              <tr>
                <td colSpan="4">
                  <span className={CheckoutPageStyles.cartTotal || ''}>
                    <span>Total: </span>
                    <em>&euro;</em>
                    <strong>{roundAndFix(total())}</strong>
                  </span>
                  <button
                    className={`button is-link ${CheckoutPageStyles.buttonGoToCheckout || ''}`}
                    onClick={() => {
                      setIsCheckoutStep(true)
                    }}
                    onKeyDown={() => {
                      setIsCheckoutStep(true)
                    }}
                  >
                    Checkout
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Checkout Step */}
        <div className={`container is-semi-narrow ${CheckoutPageStyles.checkoutStepHolder || ''}`} style={{ display: (isCheckoutStep ? 'block' : 'none') }}>
          <form
            className={'pay-form ' + (CheckoutPageStyles.form || '')}
            onSubmit={event => processForm(event)}
          >
            <div className={`billing-form ${CheckoutPageStyles.billingForm || ''}`} style={{ width: '100%' }}>
              <div className={`container is-narrow ${CheckoutPageStyles.billingFormInner || ''}`}>
                <div className='columns is-multiline'>
                  {/* Billing full name */}
                  <div className='field column is-half'>
                    <label htmlFor='name' className='label'>
                      Full Name
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='text'
                        name='name'
                        placeholder=''
                      />
                    </div>
                  </div>

                  {/* Billing phone number */}
                  <div className='field column is-half'>
                    <label htmlFor='phone' className="label">
                      Phone No.
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='tel'
                        name='phone'
                        placeholder=''
                        pattern='[0-9 +]*'
                      />
                    </div>
                  </div>

                  {/* Billing email */}
                  <div className='field column is-half'>
                    <label htmlFor='email' className='label'>
                      E-mail address
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='email'
                        name='email'
                        placeholder=''
                      />
                    </div>
                  </div>

                  {/* Billing county */}
                  <div className='field column is-half'>
                    <label htmlFor='county' className='label'>
                      County
                    </label>
                    <div className='control'>
                      <div className='select'>
                        <select // eslint-disable-line jsx-a11y/no-onchange
                          name='county'
                        >
                          <option>Dublin</option>
                          <option>Antrim</option>
                          <option>Armagh</option>
                          <option>Carlow</option>
                          <option>Cavan</option>
                          <option>Clare</option>
                          <option>Cork</option>
                          <option>Derry</option>
                          <option>Donegal</option>
                          <option>Down</option>
                          <option>Fermanagh</option>
                          <option>Galway</option>
                          <option>Kerry</option>
                          <option>Kildare</option>
                          <option>Kilkenny</option>
                          <option>Laois</option>
                          <option>Leitrim</option>
                          <option>Limerick</option>
                          <option>Longford</option>
                          <option>Louth</option>
                          <option>Mayo</option>
                          <option>Meath</option>
                          <option>Monaghan</option>
                          <option>Offaly</option>
                          <option>Roscommon</option>
                          <option>Sligo</option>
                          <option>Tipperary</option>
                          <option>Tyrone</option>
                          <option>Waterford</option>
                          <option>Westmeath</option>
                          <option>Wexford</option>
                          <option>Wicklow</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* Full address */}
                  <div className='field column is-full'>
                    <label htmlFor='address' className='label'>
                      Full address
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='text'
                        name='address'
                        placeholder=''
                      />
                    </div>
                  </div>

                  {/* City/town */}
                  <div className='field column is-half'>
                    <label htmlFor='town_city' className='label'>
                      Town/City
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='text'
                        name='town_city'
                        placeholder=''
                      />
                    </div>
                  </div>

                  {/* Billing Eircode */}
                  <div className='field column is-half'>
                    <label htmlFor='eircode' className='label'>
                      Eircode
                    </label>
                    <div className='control'>
                      <input
                        className='input'
                        type='text'
                        name='eircode'
                        placeholder=''
                      />
                    </div>
                  </div>

                  <div className={`column is-full ${CheckoutPageStyles.terms || ''}`}>
                    <span
                      className={`${CheckoutPageStyles.fauxCheckbox || ''} ${agreedTerms ? CheckoutPageStyles.isOn : CheckoutPageStyles.isOff}`}
                      onClick={() => { toggleTerms(!agreedTerms) }}
                      role="button"
                      tabIndex="0"
                    ></span>
                    <p>I agree to the <a href="/terms" target="_blank">Terms & Conditions</a> and I understand that I must return any rental product within 7 days of the end of the original rental period or my rental will automatically renew on a monthly basis (charged to the payment method provided at the time of order) until the product is returned.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`container is-narrow ${CheckoutPageStyles.actions || ''}`}>
              <div>
                <ul className={CheckoutPageStyles.prices || ''}>
                  <li>Subtotal: <strong>&euro;{total()}</strong></li>
                  <li>Delivery: {hasFreeDelivery ? 'Free' : ('€' + nationwideDeliveryCharge)}</li>
                  <li className={CheckoutPageStyles.finalTotal || ''}>Total: <strong>&euro;{hasFreeDelivery ? Number(total()) : (Number(total()) + Number(nationwideDeliveryCharge)) }</strong></li>
                </ul>

                {/* Pay Now Button */}
                <button className={`button is-link ${CheckoutPageStyles.submit || ''}`} disabled={(!submitButtonDisabled && agreedTerms ? false : true)}>
                  Pay Now
                </button>

                {!agreedTerms && <p className={CheckoutPageStyles.mustAgreeNotice || ''}>You must agree to our T&C before proceeding</p>}
              </div>
            </div>
          </form>
        </div>

        {/* Container for popup error messages */}
        <ToastContainer />
      </PageTemplate>
    )
  }
  else {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
      >
        <div className="has-text-centered">
          <p className="has-text-center has-text-primary" style={{marginBottom: '32px'}}>
            <strong>Your cart is empty.</strong>
          </p>
          <Button url="/" text="Shop Now" class="is-link is-strong" />
        </div>
      </PageTemplate>
    )
  }
}

export default PaymentPage
